
import { mixins } from 'vue-class-component';
import TasqListMixin from '@/components/tasqsCommon/TasqListMixin';
import {
  Component,
} from 'vue-property-decorator';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { VERTICAL_LIST_SORT_PRIORITY_DESC } from '@/lib/constants';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component
export default class TasqsVerticalList extends mixins(TasqListMixin, GenericMixin) {
  localListLevel: string = 'WorkTicket';
  selectedSortType = VERTICAL_LIST_SORT_PRIORITY_DESC

  async mounted() {
    tasqsListModule.setTasqListLevel(this.localListLevel);
    await this.prepareWorkTicketList({ fetchWorkTickets: false });
  }
}

import store from '@/store';
import Vue from 'vue'
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import TrackedIssue from '@/interfaces/analytics/TrackedIssue';
import getTrackedIssuesResponse from '@/graphql/userSettings/queries/getTrackedIssuesResponse.graphql';
import getOperatorDetailsQuery from '@/graphql/userSettings/queries/getOperatorDetails.graphql';
import workflowApolloClient from '@/lib/appsync/workflow';
import updateUserProfile from '@/graphql/userSettings/mutations/updateUserProfile.graphql';
import postNewAdminUser from '@/graphql/userSettings/mutations/postNewAdminUser.graphql';
import sendInvitationEmail from '@/graphql/userSettings/mutations/sendInvitationEmail.graphql';
import deleteCustomFilterMutation from '@/graphql/userSettings/mutations/deleteCustomFilterMutation.graphql';
import postCustomFilterMutation from '@/graphql/userSettings/mutations/postCustomFilterMutation.graphql';
import updateDefaultFilterMutation from '@/graphql/userSettings/mutations/updateDefaultFilterMutation.graphql';
import accountModule from '@/store/modules/accountModule';
import workflowModule from './workflowModule';
import tasqsListModule from './tasqsListModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'userProfileModule',
  store,
})
class UserProfileModule extends VuexModule {

  @Mutation
  removeCustomFilter(filterId): void {
    const customFilters = workflowModule.user.customFilters.filter(
      customFilter => customFilter.id != filterId
    );
    workflowModule.user.customFilters = customFilters
  }

  @Mutation
  addCustomFilter(newCustomFilter): void {
    workflowModule.user.customFilters.push(newCustomFilter)
  }

  @Mutation
  updateCustomFilter(newCustomFilter): void {
    const index = workflowModule.user.customFilters.findIndex(
      customFilter => customFilter.id == newCustomFilter.id
    );
    Vue.set(workflowModule.user.customFilters, index, newCustomFilter);
  }

  @Mutation
  changeDefaultFilter(pageName, filterId): void {
    workflowModule.user.defaultFilters[pageName] = pageName;
  }

  @Action
  async sendInvitationEmail({
    recipient_email,
    user_email,
    user_first_name,
  }) {
    try {
    const {
        data: {
          send_invitation_email: {
      },
        },
    } : any = await workflowApolloClient.mutate({
        mutation: sendInvitationEmail,
        variables: {
      input: {
            recipient_email,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            user_first_name,
            user_email,
      },
        },
    });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
async updateUserProfile({
  accepting_tasqs,
  first_name,
  last_name,
  phone_number,
  roles,
  team,
  user_email,
  username,
  default_page,
  default_user_filter,
  custom_profile_json
}) {
  try {
    const {
      data
    } = await workflowApolloClient.mutate({
      mutation: updateUserProfile,
      variables: {
        input: {
          first_name,
          last_name,
          ...(team && team.length && { team: team[0] }),
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        phone_number,
        roles,
        user_email,
        username,
        ...(default_page && default_page.length && {default_page}),
        ...( default_user_filter && default_user_filter.length && {default_user_filter}),

        },
      },
    });

    if (roles.length > 0) {
      accountModule.setUserRole(roles[0])
      workflowModule.setUserRole(roles[0])
    }
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}


@Action
  async postNewAdminUser({
    firstName,
    lastName,
    phoneNumber,
    email,
    team,
    role,
  }) {
    try {

      const variables = {
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phoneNumber,
        IsTestUser: false,
        Email: email.toLowerCase(),

        ...(role && role.length) && {Role: role},
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),

      }
      const {
        data: {
          post_new_admin_user: {
          },
        },
      } : any = await workflowApolloClient.mutate({
        mutation: postNewAdminUser,
        variables: {
          input: variables,
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }


  @Action
  async updateUserDetails({
    firstName,
    lastName,
    phoneNumber,
    team,
    role,
    email,
    username,
    defaultPage,
    userAdminAccess,
    adminChartAccess,
    defaultUserFilter
  }) {
    try {
      const access = !!(adminChartAccess && adminChartAccess === 'Enable')
      const adminAccess = !!(userAdminAccess && userAdminAccess === 'Enable')
      const variables = {
          accepting_tasqs: true,
            first_name: firstName,
            last_name: lastName,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          phone_number: phoneNumber,
          team: [],
          roles: [role],
          user_email: email,
          username: username,
          default_page: defaultPage,
          admin_chart_access: access,
          user_admin_access: adminAccess,
          default_user_filter: defaultUserFilter,
      }
      const {
        data
      } = await workflowApolloClient.mutate({
        mutation: updateUserProfile,
        variables: {
          input: variables,
        },
      });

    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  updateDefaultFilter({pageName, filterId}) {
    return new Promise((resolve) => {
      workflowApolloClient.mutate({
        mutation: updateDefaultFilterMutation,
        variables: {
          input: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            username: workflowModule.user.username,
            page_name: pageName,
            filter_id: filterId,
          },
        }
      }).then(({ data: { update_default_filter } } : any) => {
        this.changeDefaultFilter(pageName, filterId)
        resolve(null)
      })
    })
  }

  @Action
  deleteCustomFilter(filterId) {
    return new Promise((resolve) => {
      workflowApolloClient.mutate({
        mutation: deleteCustomFilterMutation,
        variables: {
          input: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            username: workflowModule.user.username,
            filter_id: filterId
          },
        }
      }).then(({ data: { delete_custom_filter } } : any) => {
        this.removeCustomFilter(filterId)
        resolve(null)
      })
    })
  }

  @Action
  saveCustomFilter({
    filterId, filterName, filterLevel, pageName, filterSettings
  }) {
    return new Promise((resolve) => {
      workflowApolloClient.mutate({
        mutation: postCustomFilterMutation,
        variables: {
          input: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            username: workflowModule.user.username,
            filter_id: filterId,
            filter_name: filterName,
            filter_level: filterLevel,
            page_name: pageName,
            filter_settings: filterSettings
          },
        }
      }).then(({ data: { post_custom_filter } } : any) => {
        const newFilter = {
          id: post_custom_filter.filter_id,
          name: filterName,
          level: filterLevel,
          settings: JSON.parse(filterSettings)
        }
        if (filterId) {
          this.updateCustomFilter(newFilter)
        } else {
          this.addCustomFilter(newFilter)
        }
        resolve(newFilter)
      })
    })
  }

  @Action
  async updateAcceptingTasqs({
    accepting_tasqs,
    username,
  }) {
    try {
      const {
        data
      } = await workflowApolloClient.mutate({
        mutation: updateUserProfile,
        variables: {
          input: {
        accepting_tasqs,
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        username,

          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  getOperatorDetails() {
    return new Promise((resolve) => {
      workflowApolloClient.query({
        query: getOperatorDetailsQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED')
          }
        }
      }).then(({ data: { get_operator_details : { Results } } } : any) => {
        resolve(JSON.parse(Results))
      })
    })
  }
}

export default getModule(UserProfileModule);
